import React from "react";
const Footer = (props) => {
  // const getLiChildren = (data) =>
  //   data.map((item, i) => {
  //     const { title, childWrapper, ...itemProps } = item;
  //     return (
  //       <Col key={i.toString()} {...itemProps} title={null} content={null}>
  //         <h2 {...title}>
  //           {typeof title.children === "string" &&
  //           title.children.match(isImg) ? (
  //             <img src={title.children} width="100%" alt="img" />
  //           ) : (
  //             title.children
  //           )}
  //         </h2>
  //         <div {...childWrapper}>
  //           {childWrapper.children.map(getChildrenToRender)}
  //         </div>
  //       </Col>
  //     );
  //   });

  const { dataSource } = props;
  // const childrenToRender = getLiChildren(dataSource.blocks);

  return (
    <div className="home-page-wrapper footer1-wrapper">
      <div className="footer1">
        {/* <Row gutter={0} className="home-page">
          {childrenToRender}
        </Row> */}

        <div className="copyright-wrapper">
          <div>
            <div className="copyright-wrapper-info">NewFW | Is just a startup! | under constructing...</div>
            {/* <div className="copyright-wrapper-link" style={{ marginLeft: "10px" }}>
              wechat, facebook, twitter, instagram, youtube
            </div> */}
          </div>
          <div className="home-page">
            <div className="copyright">{dataSource.copyright}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
