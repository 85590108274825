import React from "react";
import QueueAnim from "rc-queue-anim";
import { Row, Col } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";

const Content = (props) => {
  const { dataSource } = props;

  return (
    <div className="home-page-wrapper content0-wrapper" style={{ backgroundColor: dataSource.backgroundColor }}>
      <div className="anchor" id={dataSource.id}></div>
      <div className="home-page content0">
        <div className="title-wrapper">
          <h2>{dataSource.title}</h2>
        </div>
          <Row className="content0-block-wrapper">
            {dataSource.items.map((item, i) => {
              return (
                <Col key={i.toString()} className="content0-block" {...dataSource.blockWrapper}>
                  <div className="content0-block-item">
                    <img src={item.img} alt=""></img>
                    <h3 style={{margin: '20px 0', textAlign: 'left'}}>{item.title}</h3>
                    <p style={{textAlign: 'left'}}>{item.desc}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
      </div>
    </div>
  );
};

export default Content;
