import React, { useState } from "react";
import { motion } from "motion/react";
import { Menu, Dropdown, Space } from "antd";
import { DownOutlined, GlobalOutlined, XFilled } from "@ant-design/icons";

const { Item, SubMenu } = Menu;
const Header3 = (props) => {
  const { dataSource, isMobile } = props;

  const [phoneOpen, setPhoneOpen] = useState(false);
  const [openMenuIndex, setOPenMenuIndex] = useState(-1);

  const phoneClick = () => {
    setPhoneOpen(!phoneOpen);
  };

  const renderMobileMenuItem = (item) => {
    const hasSubItems = item.children && item.children.length > 0;
    if (hasSubItems) {
      return (
        <SubMenu
          key={item.name}
          title={<div className="header3-item-block">{item.name}</div>}
          popupClassName="header3-item-child"
        >
          {item.children.map(($item) => {
            return (
              <Item key={`${item.name}_${$item.name}`} {...$item}>
                <a className="header3-item-block" href={$item.href}>
                  {$item.name}
                </a>
              </Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Item key={item.name}>
        <a className="header3-item-block" href={item.href}>
          {item.name}
        </a>
      </Item>
    );
  }

  const menuItems = dataSource.menu.items.map((item) => {
    const hasSubItems = item.children && item.children.length > 0;
    if (hasSubItems) {
      return (
        <SubMenu
          key={item.name}
          title={<div className="header3-item-block">{item.name}</div>}
          popupClassName="header3-item-child"
        >
          {item.children.map(($item, ii) => {
            return renderMobileMenuItem($item);
          })}
        </SubMenu>
      );
    }
    return (
      <Item key={item.name}>
        <a className="header3-item-block" href={item.href}>
          {item.name}
        </a>
      </Item>
    );
  });

  const renderMobileMenu = () => {
    return (
      <>
        {renderLanguageSwitch()}
        <div className={`${phoneOpen ? "open" : ""}`}>
          <div className="header3-mobile-menu" onClick={phoneClick}>
            <em />
            <em />
            <em />
          </div>
        </div>
      </>
    );
  };

  const renderLanguageSwitch = () => {
    const langItems = [
      {
        key: "zh",
        label: (
          <div style={{ fontSize: "16px", margin: "10px 20px" }}>简体中文</div>
        ),
      },
      {
        key: "en",
        label: (
          <div style={{ fontSize: "16px", margin: "10px 20px" }}>English</div>
        ),
      },
    ];

    return (
      <div>
        {/* <Dropdown 
          menu={{ 
            items: langItems, 
            style: { padding: "10px" }
          }}
          overlayStyle={{ marginTop: "20px" }}
          trigger="click"
        > */}
          <span className="header3-dropdown-btn">
            <GlobalOutlined style={{ fontSize: "28px" }} />
            <span style={{ 
              marginLeft: '-5px',
              backgroundColor: '#ffffffff',
              lineHeight: '10px',
              fontSize: '14px',
              fontWeight: 'normal',
            }}>EN</span>
          </span>
        {/* </Dropdown> */}
      </div>
    );
  };

  const renderDesktopMenu = () => {
    const dropItems = dataSource.menu.items.map((item, idx) => {
      const hasSubItems = item.children && item.children.length > 0;
      if (!hasSubItems) {
        return (
          <div
            className="header3-dropdown-btn"
            type="text"
            onClick={(e) => {
              setOPenMenuIndex(-1);
              window.location.href = item.href;
            }}
          >
            <Space>{item.name}</Space>
          </div>
        );
      }

      const menuItems = item.children.map((x) => ({
        key: x.name,
        label: (
          <div onClick={() => {
            setOPenMenuIndex(-1);
            window.location.href = x.href;
          }}>
            <div style={{ padding: "5px" }}>
              <div className="header3-menu-item-title">{x.name}</div>
              {x.desc && <div className="header3-menu-item-desc">{x.desc}</div>}
            </div>
          </div>
        ),
        children: (x.children || []).length <= 0 ? null : (x.children || []).map((y) => ({
          key: y.name,
          label: (
            <div onClick={() => {
              setOPenMenuIndex(-1);
              window.location.href = y.href;
            }}>
              <div style={{ padding: "5px" }}>
                <div className="header3-submenu-item-title">{y.name}</div>
              </div>
            </div>
          ),
          style: { padding: "10px", minWidth: '240px' }
        })),
      }));

      return (
        <Dropdown
          menu={{ 
            items: menuItems, 
            style: { padding: "10px", minWidth: '300px' }
          }}
          arrow={true}
          overlayStyle={{ marginTop: "20px" }}
          onOpenChange={(open) => setOPenMenuIndex(open ? idx : -1)}
        >
          <div
            className="header3-dropdown-btn"
            type="text"
            onClick={(e) => e.preventDefault()}
          >
            <Space>
              {item.name}
              <motion.div
                animate={{ rotate: openMenuIndex === idx ? 180 : 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <DownOutlined style={{ fontSize: "14px", strokeWidth: "50" }} />
              </motion.div>
            </Space>
          </div>
        </Dropdown>
      );
    });

    return (
      <>
        {dropItems}
        <div style={{ flexGrow: 1 }}></div>
        {renderLanguageSwitch()}
      </>
    );
  };

  return (
    <div>
      <div className="header3">
        <div className="home-page">
          <div className="header3-logo">
            NewFW
            {/* <img width="100%" src={dataSource.logo} alt="img" /> */}
          </div>
          <div
            style={{ marginRight: "32px", flexGrow: isMobile ? 1 : 0 }}
          ></div>

          {isMobile && renderMobileMenu()}
          {!isMobile && renderDesktopMenu()}
        </div>
        <motion.div
          className="header3-menu"
          animate={{
            opacity: phoneOpen ? 1 : 0,
            height: phoneOpen ? "90vh" : "0vh",
            transition: { duration: 0.25 },
          }}
        >
          <Menu
            style={{ border: "none" }}
            mode="inline"
            defaultSelectedKeys={["sub0"]}
            theme="light"
            onClick={() => {setPhoneOpen(false)}}
          >
            {menuItems}
          </Menu>
        </motion.div>
      </div>
      <div style={{ height: "66px" }}></div>
    </div>
  );
};

export default Header3;
