import React from "react";
import { Row, Col, Button } from "antd";

const Cwp = (props) => {
  const { dataSource } = props;

  return (
    <div
      className="home-page-wrapper cwp-wrapper"
      style={{ backgroundColor: dataSource.backgroundColor }}
    >
      <div className="anchor" id={dataSource.id}></div>
      <div className="home-page cwp">
        {dataSource.title && (
          <div className="title-wrapper">
            <h2>{dataSource.title}</h2>
          </div>
        )}
        <Row className="cwp-block-wrapper">
          <Col className="cwp-block" md={9} xs={24}>
            <div className="cwp-block-item">
              <h2 style={{ margin: "20px 0", textAlign: "left" }}>
                {dataSource.subtitle}
              </h2>
              <div className="cwp-block-desc">{dataSource.desc}</div>
              <Button key="button" className="cwp-block-button">
                {dataSource.button}
              </Button>
            </div>
          </Col>
          <Col className="cwp-block" md={15} xs={24}>
            <img style={{ width: "100%" }} src={dataSource.img} alt=""></img>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Cwp;
