import React from "react";
import QueueAnim from "rc-queue-anim";
import { Row, Col, Button } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";

const Uefi = (props) => {
  const { dataSource } = props;

  return (
    <div className="home-page-wrapper uefi-wrapper" style={{ backgroundColor: dataSource.backgroundColor }}>
      <div className="anchor" id={dataSource.id}></div>
      <div className="home-page uefi">
        <div className="title-wrapper">
          <h2>{dataSource.title}</h2>
          <h4 className="uefi-subtitle">{dataSource.subtitle}</h4>
        </div>
          <div className="uefi-block-wrapper">
            <img style={{width: '100%'}} src={dataSource.img} alt=""></img>
          </div>
      </div>
    </div>
  );
};

export default Uefi;
