import React from "react";
import { Button, Row, Col } from "antd";

const Banner = (props) => {
  const { dataSource, isMobile } = props;

  return (
    <div className="banner0">
      <Row>
        <Col sm={isMobile ? 24 :18}>
          <div className="banner0-text-wrapper">
            <div className="banner0-title">{dataSource.title}</div>
            <div className="banner0-content">{dataSource.desc}</div>
            <Button ghost key="button" className="banner0-button">
              {dataSource.button}
            </Button>
          </div>
        </Col>
        {/* <Col sm={12}>
          <div className="banner0-img-wrapper">
            <img src={dataSource.introImg} alt="img" />
          </div>
        </Col> */}
      </Row>
    </div>
  );
};

export default Banner;
