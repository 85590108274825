import React from "react";
import BG_IMG from './bg.jpg';
import LOGO from "./logo.jpg";
import ADVANTAGE_IMG1 from "./advantage1.jpg";
import ADVANTAGE_IMG2 from "./advantage2.jpg";
import ADVANTAGE_IMG3 from "./advantage3.jpg";
import DEVOPS_IMG from "./devops.jpg";
import CWP_IMG from "./cwp.png";
import UEFI_IMG from "./uefi.png";
import BANNER_IMG from "./banner.jpg";
import CWP_SOL_IMG1 from './cwp_sol1.jpg';
import CWP_SOL_IMG2 from './cwp_sol2.jpg';
import CWP_SOL_IMG3 from './cwp_sol3.jpg';
import CWP_SOL_IMG4 from './cwp_sol4.jpg';
import ARCHITECTURE_IMG from './architectureoverview.jpg';
import CLOUD_IMG from './cloudfirmware.jpg';
import BMC_IMG from './bmcfirmware.jpg';

export const Nav30DataSource = {
  logo: LOGO,
  menu: {
    items: [
      {
        name: "PRODUCTS",
        children: [
          {
            name: "Cloud Workload Platform",
            href: "#cwp",
          },
          {
            name: "UEFI UniversalPayload Firmware",
            href: "#uefi",
          },
          {
            name: "SlimBMC Firmware",
            href: "#bmc",
          },
        ],
      },
      {
        name: "SOLUTIONS",
        children: [
          {
            name: 'Firmware & System',
            href: "#cwpsolutions",
            children: [
              {
                name: 'Architecture Overview',
                href: "#architecture",
              },
              {
                name: 'Cloud Firmware Stack',
                href: "#cloud",
              }, {
                name: 'RISC-V Firmware Stack',
                href: "#cwpsolutions",
              }, {
                name: 'BMC Firmware Stack',
                href: "#bmc",
              }
            ],
          },
          {
            name: 'NewFW Infra',
            href: "#cwpsolutions",
            children: [
              {
                name: "Workloads Orchestration",
                href: "#cwpsolutions",
              },
              {
                name: "Manufacturing Validation",
                href: "#cwpsolutions",
              },
              {
                name: "AI Benchmarking",
                href: "#cwpsolutions",
              },
              {
                name: "Data Center Monitoring",
                href: "#cwpsolutions",
              },
            ],
          },
          {
            name: 'DevOps For Firmware & Workloads',
            href: "#devops",
          },
        ],
      },
      {
        name: "ADVANTAGES",
        href: "#advantages",
      },
    ],
  },
};
export const Banner00DataSource = {
  title: "NewFW Infrastructure",
  desc: "Accelerate and deploy AI infrastructure from bottom to up !",
  introImg: BANNER_IMG,
  // button: "Get Started",
  bgImage: BG_IMG,
};

export const Banner10DataSource = {
  wrapper: { className: "banner1" },
  BannerAnim: {
    children: [
      {
        name: "elem0",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "banner1-text-wrapper" },
        bg: { className: "bg bg0" },
        title: {
          className: "banner1-title",
          children:
            "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
        },
        content: {
          className: "banner1-content",
          children: "一个高效的页面动画解决方案",
        },
        button: { className: "banner1-button", children: "Learn More" },
      },
      {
        name: "elem1",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "banner1-text-wrapper" },
        bg: { className: "bg bg1" },
        title: {
          className: "banner1-title",
          children:
            "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
        },
        content: {
          className: "banner1-content",
          children: "一个高效的页面动画解决方案",
        },
        button: { className: "banner1-button", children: "Learn More" },
      },
      {
        name: "elem2",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "banner1-text-wrapper" },
        bg: { className: "bg bg1" },
        title: {
          className: "banner1-title",
          children:
            "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
        },
        content: {
          className: "banner1-content",
          children: "一个高效的页面动画解决方案",
        },
        button: { className: "banner1-button", children: "Learn More" },
      },
    ],
  },
};
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper",
    children: [
      { name: "title", children: "产品名", className: "banner5-title" },
      {
        name: "explain",
        className: "banner5-explain",
        children: "产品标语介绍",
      },
      {
        name: "content",
        className: "banner5-content",
        children: "产品的详细说明，如是什么东西之类的文字",
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "#",
          className: "banner5-button",
          type: "primary",
          children: "开始使用",
        },
      },
    ],
  },
  image: {
    className: "banner5-image",
    children:
      "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ",
  },
};
export const CwpDataSource = {
  id: 'cwp',
  backgroundColor: '#fff',
  title: "What Is Cloud Workload Platform?",
  subtitle: 'NewFW Cloud Workload Platform (CWP) is a unified platform designed to manage, deploy, monitor, and optimize workloads across multi-cloud, hybrid, bare metal, and edge infrastrutures.',
  img: CWP_IMG,
};
export const CwpSolutionDataSource = {
  id: 'cwpsolutions',
  title: "Solutions That NewFW CWP Can Provide",
  backgroundColor: '#fafafa',
  blockWrapper: { lg: 6, md: 12, xs: 24 },
  items: [{
    title: 'Workloads Orchestration',
    desc: 'Automate workload deployment across clouds. Optimize resources, enhance efficiency, and ensure consistent performance in hybrid environments.',
    img: CWP_SOL_IMG1,
  }, {
    title: 'Manufacturing Validation',
    desc: 'Automate electronic component testing. Analyze data, report defects early, reduce costs, and improve manufacturing quality.',
    img: CWP_SOL_IMG2,
  }, {
    title: 'AI Benchmarking',
    desc: 'Evaluate AI model performance. Measure throughput, latency, and accuracy for optimal AI deployment and resource allocation.',
    img: CWP_SOL_IMG3,
  }, {
    title: 'Data Center Monitoring',
    desc: 'Real-time data center visibility. Proactive alerts, analytics, and control for high availability and infrastructure optimization.',
    img: CWP_SOL_IMG4,
  }],
};
export const UefiDataSource = {
  id: 'uefi',
  backgroundColor: '#FFF',
  subtitle: "UEFI UniversalPayload Firmware",
  desc: (<>The NewFW UEFI UniversalPayload Firmware product utilizes open source 
  projects <span style={{color: '#76b900'}}>Linux as the firmware</span>, integrating <span style={{color: '#76b900'}}>u-root</span>’s Go-based implementations of
    standard Linux tools and <span style={{color: '#76b900'}}>USF UniversalPayload</span> to offer a scalable, upgradable, and efficient solution for rapid validation and deployment as a firmware deliverable.</>),
  img: UEFI_IMG,
};
export const ArchitectureDataSource = {
  id: 'architecture',
  backgroundColor: '#FAFAFA',
  title: 'NewFW Firmware Architecture(USF Based) Overview',
  img: ARCHITECTURE_IMG,
};
export const CloudDataSource = {
  id: 'cloud',
  backgroundColor: '#FFF',
  title: 'NewFW Clould Firmware Stack',
  img: CLOUD_IMG,
};
export const BMCDataSource = {
  id: 'bmc',
  backgroundColor: '#FAFAFA',
  title: 'NewFW BMC Stack',
  img: BMC_IMG,
};
export const DevOpsDataSource = {
  id: 'devops',
  backgroundColor: '#fff',
  subtitle: (<>AI-Enabled <span style={{ color: '#76b900'}}>DevOps</span> for Firmware & Workloads</>),
  desc: (<>AI-powered DevOps integrates <span style={{ color: '#76b900'}}>CWP</span> to streamline firmware updates and workload orchestration, enabling real-time collaboration, predictive analytics, and automated CI/CD pipelines for resilient cross-platform operations.</>),
  img: DEVOPS_IMG,
};
export const Content00DataSource = {
  title: "Our Advantages",
  id: 'advantages',
  backgroundColor: '#FAFAFA',
  blockWrapper: { md: 8, xs: 24 },
  items: [{
    title: 'Customer Always First',
    desc: 'We listen, learn and act on our customer’s needs. We deliver solutions, products and services that our customers can always rely on.',
    img: ADVANTAGE_IMG1,
  }, {
    title: 'Results Driven',
    desc: 'We stay focused and execute flawlessly with urgency. We deliver to our customer and partner commitments with clarity and speed.',
    img: ADVANTAGE_IMG2,
  }, {
    title: 'Quality Orientation',
    desc: 'We achieve and deliver high quality standards. We value, grow wide range of talents, and commit to team success.',
    img: ADVANTAGE_IMG3,
  }],
};
export const Content10DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children: "https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png",
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: { className: "content1-title", children: "企业资源管理" },
  content: {
    className: "content1-content",
    children:
      "云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。",
  },
};
export const Feature40DataSource = {
  wrapper: { className: "home-page-wrapper content6-wrapper" },
  OverPack: { className: "home-page content6" },
  textWrapper: { className: "content6-text", xs: 24, md: 10 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: "蚂蚁金融云提供专业的服务",
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: "基于阿里云计算强大的基础资源",
      },
    ],
  },
  img: {
    children: "https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png",
    className: "content6-img",
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: "block0",
        img: {
          children:
            "https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png",
          className: "content6-icon",
        },
        title: { className: "content6-title", children: "技术" },
        content: {
          className: "content6-content",
          children:
            "丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。",
        },
      },
      {
        name: "block1",
        img: {
          className: "content6-icon",
          children:
            "https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png",
        },
        title: { className: "content6-title", children: "融合" },
        content: {
          className: "content6-content",
          children:
            "解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。",
        },
      },
      {
        name: "block2",
        img: {
          className: "content6-icon",
          children:
            "https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png",
        },
        title: { className: "content6-title", children: "开发" },
        content: {
          className: "content6-content",
          children:
            "符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。",
        },
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: { className: "home-page-wrapper pricing1-wrapper" },
  page: { className: "home-page pricing1" },
  OverPack: { playScale: 0.3, className: "pricing1-content-wrapper" },
  titleWrapper: {
    className: "pricing1-title-wrapper",
    children: [
      { name: "title", children: "价目表", className: "pricing1-title-h1" },
    ],
  },
  block: {
    className: "pricing1-block-wrapper",
    children: [
      {
        name: "block0",
        className: "pricing1-block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "pricing1-block-box " },
          topWrapper: { className: "pricing1-top-wrapper" },
          name: { className: "pricing1-name", children: "Free" },
          money: { className: "pricing1-money", children: "¥0" },
          content: {
            className: "pricing1-content",
            children: (
              <span>
                140-500Mbps
                <br /> 140 GB-50TB（含）
                <br /> 14500GB流量包
                <br /> 14国内按峰值宽带账单
                <br /> 14弹性计算
                <br /> 14云服务器 ECS{" "}
              </span>
            ),
          },
          line: { className: "pricing1-line" },
          buttonWrapper: {
            className: "pricing1-button-wrapper",
            children: {
              a: {
                className: "pricing1-button",
                href: "#",
                children: "免费试用",
              },
            },
          },
        },
      },
      {
        name: "block1",
        className: "pricing1-block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "pricing1-block-box active" },
          topWrapper: { className: "pricing1-top-wrapper" },
          name: { className: "pricing1-name", children: "Starter" },
          money: { className: "pricing1-money", children: "¥199" },
          content: {
            className: "pricing1-content",
            children: (
              <span>
                14500-5Gbps
                <br />
                1410 GB-50TB（含）
                <br />
                141TB流量包
                <br />
                14国内按峰值宽带账单
                <br />
                14弹性计算
                <br />
                云服务器 ECS
              </span>
            ),
          },
          line: { className: "pricing1-line" },
          buttonWrapper: {
            className: "pricing1-button-wrapper",
            children: {
              a: {
                className: "pricing1-button",
                href: "#",
                children: "立即购买",
              },
            },
          },
        },
      },
      {
        name: "block2",
        className: "pricing1-block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "pricing1-block-box " },
          topWrapper: { className: "pricing1-top-wrapper" },
          name: { className: "pricing1-name", children: "Pro" },
          money: { className: "pricing1-money", children: "¥999" },
          content: {
            className: "pricing1-content",
            children: (
              <span>
                14大于5Gbps
                <br />
                1450 GB-100TB（含）
                <br />
                145TB流量包
                <br />
                14国内按峰值宽带账单
                <br />
                14弹性计算
                <br />
                14云服务器 ECS
              </span>
            ),
          },
          line: { className: "pricing1-line" },
          buttonWrapper: {
            className: "pricing1-button-wrapper",
            children: {
              a: {
                className: "pricing1-button",
                href: "#",
                children: "立即购买",
              },
            },
          },
        },
      },
    ],
  },
};
export const Teams20DataSource = {
  wrapper: { className: "home-page-wrapper teams2-wrapper" },
  page: { className: "home-page teams2" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: "团队成员" }],
  },
  block: {
    className: "block-wrapper",
    gutter: 72,
    children: [
      {
        name: "block0",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            { name: "title", className: "teams2-title", children: "叶秀英" },
            {
              name: "content",
              className: "teams2-job",
              children: "公司+职位 信息暂缺",
            },
            {
              name: "content1",
              className: "teams2-content",
              children: "AntV 是蚂蚁金服全新一代数据可视化解决方案。",
            },
          ],
        },
      },
      {
        name: "block1",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*njqxS5Ky7CQAAAAAAAAAAABjARQnAQ",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            { name: "title", className: "teams2-title", children: "韩勇" },
            {
              name: "content",
              className: "teams2-job",
              children: "公司+职位 信息暂缺",
            },
            {
              name: "content1",
              className: "teams2-content",
              children: "语雀是一款优雅高效的在线文档编辑与协同工具。",
            },
          ],
        },
      },
      {
        name: "block2",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            { name: "title", className: "teams2-title", children: "叶秀英" },
            {
              name: "content",
              className: "teams2-job",
              children: "公司+职位 信息暂缺",
            },
            {
              name: "content1",
              className: "teams2-content",
              children: "AntV 是蚂蚁金服全新一代数据可视化解决方案。",
            },
          ],
        },
      },
      {
        name: "block3",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            { name: "title", className: "teams2-title", children: "叶秀英" },
            {
              name: "content",
              className: "teams2-job",
              children: "公司+职位 信息暂缺",
            },
            {
              name: "content1",
              className: "teams2-content",
              children: "AntV 是蚂蚁金服全新一代数据可视化解决方案。",
            },
          ],
        },
      },
      {
        name: "block4",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*njqxS5Ky7CQAAAAAAAAAAABjARQnAQ",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            { name: "title", className: "teams2-title", children: "韩勇" },
            {
              name: "content",
              className: "teams2-job",
              children: "公司+职位 信息暂缺",
            },
            {
              name: "content1",
              className: "teams2-content",
              children: "语雀是一款优雅高效的在线文档编辑与协同工具。",
            },
          ],
        },
      },
      {
        name: "block5",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            { name: "title", className: "teams2-title", children: "叶秀英" },
            {
              name: "content",
              className: "teams2-job",
              children: "公司+职位 信息暂缺",
            },
            {
              name: "content1",
              className: "teams2-content",
              children: "AntV 是蚂蚁金服全新一代数据可视化解决方案。",
            },
          ],
        },
      },
      {
        name: "block6",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            { name: "title", className: "teams2-title", children: "叶秀英" },
            {
              name: "content",
              className: "teams2-job",
              children: "公司+职位 信息暂缺",
            },
            {
              name: "content1",
              className: "teams2-content",
              children: "AntV 是蚂蚁金服全新一代数据可视化解决方案。",
            },
          ],
        },
      },
      {
        name: "block7",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*njqxS5Ky7CQAAAAAAAAAAABjARQnAQ",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            { name: "title", className: "teams2-title", children: "韩勇" },
            {
              name: "content",
              className: "teams2-job",
              children: "公司+职位 信息暂缺",
            },
            {
              name: "content1",
              className: "teams2-content",
              children: "语雀是一款优雅高效的在线文档编辑与协同工具。",
            },
          ],
        },
      },
      {
        name: "block8",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            { name: "title", className: "teams2-title", children: "叶秀英" },
            {
              name: "content",
              className: "teams2-job",
              children: "公司+职位 信息暂缺",
            },
            {
              name: "content1",
              className: "teams2-content",
              children: "AntV 是蚂蚁金服全新一代数据可视化解决方案。",
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: "蚂蚁金融云提供专业的服务",
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: "基于阿里云强大的基础资源",
      },
    ],
  },
  block: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png",
          },
          textWrapper: { className: "content3-text" },
          title: { className: "content3-title", children: "企业资源管理" },
          content: {
            className: "content3-content",
            children:
              "云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。",
          },
        },
      },
      {
        name: "block1",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png",
          },
          textWrapper: { className: "content3-text" },
          title: { className: "content3-title", children: "云安全" },
          content: {
            className: "content3-content",
            children:
              "按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。",
          },
        },
      },
      {
        name: "block2",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png",
          },
          textWrapper: { className: "content3-text" },
          title: { className: "content3-title", children: "云监控" },
          content: {
            className: "content3-content",
            children:
              "分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。",
          },
        },
      },
      {
        name: "block3",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png",
          },
          textWrapper: { className: "content3-text" },
          title: { className: "content3-title", children: "移动" },
          content: {
            className: "content3-content",
            children:
              "一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。",
          },
        },
      },
      {
        name: "block4",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png",
          },
          textWrapper: { className: "content3-text" },
          title: { className: "content3-title", children: "分布式中间件" },
          content: {
            className: "content3-content",
            children:
              "金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。",
          },
        },
      },
      {
        name: "block5",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png",
          },
          textWrapper: { className: "content3-text" },
          title: { className: "content3-title", children: "大数据" },
          content: {
            className: "content3-content",
            children:
              "一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。",
          },
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  blocks: [
    {
      name: "block0",
      xs: 24,
      md: 6,
      className: "block",
      title: {
        className: "logo",
        children:
          "https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg",
      },
      childWrapper: {
        className: "slogan",
        children: [
          {
            name: "content0",
            children: "Animation specification and components of Ant Design.",
          },
        ],
      },
    },
    {
      name: "block1",
      xs: 24,
      md: 6,
      className: "block",
      title: { children: "产品" },
      childWrapper: {
        children: [
          { name: "link0", href: "#", children: "产品更新记录" },
          { name: "link1", href: "#", children: "API文档" },
          { name: "link2", href: "#", children: "快速入门" },
          { name: "link3", href: "#", children: "参考指南" },
        ],
      },
    },
    {
      name: "block2",
      xs: 24,
      md: 6,
      className: "block",
      title: { children: "关于" },
      childWrapper: {
        children: [
          { href: "#", name: "link0", children: "FAQ" },
          { href: "#", name: "link1", children: "联系我们" },
        ],
      },
    },
    {
      name: "block3",
      xs: 24,
      md: 6,
      className: "block",
      title: { children: "资源" },
      childWrapper: {
        children: [
          { href: "#", name: "link0", children: "Ant Design" },
          { href: "#", name: "link1", children: "Ant Motion" },
        ],
      },
    },
  ],
  copyright: (
    <span>
      Copyright © 2025 NewFW All Rights Reserved.
    </span>
  ),
};
export const Footer00DataSource = {
  wrapper: { className: "home-page-wrapper footer0-wrapper" },
  OverPack: { className: "home-page footer0", playScale: 0.05 },
  copyright: {
    className: "copyright",
    children: (
      <span>
        ©2018 <a href="https://motion.ant.design">Ant Motion</a> All Rights
        Reserved
      </span>
    ),
  },
};
