import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

function Content4(props) {
  const { dataSource } = props;

  return (
    <div className="home-page-wrapper content4-wrapper" style={{ backgroundColor: dataSource.backgroundColor }}>
      <div className='anchor' id={dataSource.id}></div>
      <div className='home-page content4'>
        <div key="title" className='title-wrapper'>
          <h2>{dataSource.title}</h2>
        </div>
          <div
            key="img"
            className='content4-media'
          >
            <img src={dataSource.img} alt=''></img>
          </div>
      </div>
    </div>
  );
}

export default Content4;
