import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Home/Nav3';
import Footer from './Home/Footer1';
import Home from './Home';
import AboutUs from './AboutUs';

import {
  Nav30DataSource,
  Footer10DataSource,
} from './Home/data.source.js';

const App = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile(!!b);
    });
  }, []);

  return (
    <Router>
      <div>
        <Header dataSource={Nav30DataSource} isMobile={isMobile} />
        <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={AboutUs} />
        {/* <Footer dataSource={Footer10DataSource} isMobile={isMobile} /> */}
      </div>
    </Router>
  );
}

export default App;
